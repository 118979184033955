import { createTheme } from "@mui/material"; 
import SocialGothicForSHL from './assets/SocialGothicForSHL.otf';
import Montserrat from './assets/Montserrat-VariableFont_wght.ttf';


export const bodyFont = createTheme({
    palette: {
        type: 'dark',
        mode: 'dark',
        primary: {
          main: '#EF722F',
        },
        secondary: {
          main: '#056ebb',
        },
        background: {
          default: '#131313',
          paper: '#ffcc04',
        },
        text: {
          primary: '#ffffff',
        },
        
      },
    typography: {
      fontFamily: [
        'Montserrat',
        'serif',
      ].join(','),
  },});

export const themeOptions = createTheme({
  palette: {
    type: 'dark',
    mode: 'dark',
    primary: {
      main: '#EF722F',
    },
    secondary: {
      main: '#056ebb',
    },
    background: {
      default: '#131313',
      paper: '#ffcc04',
    },
    text: {
      primary: '#ffffff',
    },
    
  },
  typography: {
    fontFamily: 'Raleway, Arial',
    fontColor: '#FFFFFF', 
    fontSize: 30,
  },
  components: {
    MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('SocialGothicForSHL'), url(${SocialGothicForSHL}) format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    }
});