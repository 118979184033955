import './App.css';
import React, { useState, useEffect } from 'react';
import QuestionForm from './components/QuestionForm';
import Welcome from './components/Welcome';
import Submitted from './components/Submitted';
import { Box, Container } from '@mui/system';
import  toplogo  from './assets/vlhyotf.png';
import logo_  from './assets/vlhvip.png';
import logoBig from './assets/logo_lakers_quiz_start.png';
import logoSmall from './assets/logo_quiz_lakers.png';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { themeOptions } from './themeOptions';
import axios from 'axios';


//TODO: 
/*

retry send
styling fix

*/

function App() {

  const API_ERROR_RETRIES = 3;

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(10);
  const [currentState, setCurrentState] = useState(0);
  const [isValid, setIsValid] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [acceptGDPR, setAcceptGDPR] = useState(false)
  const [gameID, setGameID] = useState(1)

  var id=1

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const _id = queryParams.get('id');

    if(_id == 2) {
      id = 2;
    }
    
    if(localStorage.getItem("gameID") != null) {
      if(localStorage.getItem("gameID") != id) {
        localStorage.clear();
      }
    }
    else {
      localStorage.setItem("gameID", id);
    }
    setGameID(id)
   // console.log("gameID: "+gameID)
     
    

    setName(localStorage.getItem("name") != null ? localStorage.getItem("name") : "")
    setEmail(localStorage.getItem("email") != null ? localStorage.getItem("email") : "")
    setAcceptGDPR(localStorage.getItem("acceptGDPR")!= null ? localStorage.getItem("acceptGDPR") ==="true" : false)

    if(localStorage.getItem('answers') != null) {
      setAnswers(JSON.parse(localStorage.getItem('answers')));
    }

    if(localStorage.getItem('currentQuestion') != null) {
      //(localStorage.getItem('currentQuestion'));
      setCurrentQuestion(JSON.parse(localStorage.getItem('currentQuestion')));
     if(isValidUserData()) {
        //console.log("valid");
        setCurrentState(1);
      }
      else {
        //("not valid");
      }
    }
    else {
      localStorage.setItem('currentQuestion', currentQuestion);
    }

  
  }, []);

  useEffect(() => {
    setIsValid(name.length > 0 && isValidEmail(email) && acceptGDPR)
  }, [name, email, acceptGDPR])

  const isValidUserData = () => {
    const _name = localStorage.getItem("name") != null ? localStorage.getItem("name") : "";
    const _email = localStorage.getItem("email") != null ? localStorage.getItem("email") : "";
    const _acceptGDPR = localStorage.getItem("acceptGDPR")!= null ? localStorage.getItem("acceptGDPR") ==="true" : false;
    return(_name.length > 0 && isValidEmail(_email) && _acceptGDPR)
    
  }

  const onAcceptGDPR = (e) => {
      setAcceptGDPR(e.target.checked)
      localStorage.setItem("acceptGDPR", e.target.checked)
  }

  const setUserName = (e) => {
      setName(e.currentTarget.value)
      localStorage.setItem("name", e.currentTarget.value)
  }

  const setUserEmail = (e) => {
      setEmail(e.currentTarget.value)
      localStorage.setItem("email", e.currentTarget.value)
  }

  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  const postAnswers = async (e) => {
    e.preventDefault();
    //console.log('posting answers');
    const formData = new FormData();
    formData.append("quiz_id",gameID);
    formData.append("name", localStorage.getItem('name'));
    formData.append("email", localStorage.getItem('email'));
    formData.append("answers", localStorage.getItem('answers'));
    formData.append("store_result", "true");
    for (var i = 0; i < API_ERROR_RETRIES; i++) {
      try{ 
      const resp = await axios({
        method: "post",
        url: "https://vlhquiz.fancam.se/backend/store.php",
        data: formData,
        
      })
      
        //console.log(resp.res);
        //console.log(resp.data);
        break;
      }
      catch(err) {
        //console.log(err);
        await sleep(3000);
      }
    }
    localStorage.clear();
    setCurrentState(2)
  }

  const isValidEmail = (email) => {
    //return true
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search (filter) != -1;
   
}


  const onNextQuestion = (e) => {
    e.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    localStorage.setItem('currentQuestion', currentQuestion + 1);
  }

  const onPrevQuestion = (e) => {
    e.preventDefault();
    if(currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      localStorage.setItem('currentQuestion', currentQuestion - 1);
    }
    else
    {
      setCurrentState(0)
    }
  }

  const onChange = (e) => {
    e.preventDefault();
   
    var a = JSON.parse(localStorage.getItem('answers')) || [];
    
   // a.splice(currentQuestion - 1, 0, e.target.value);
    a[currentQuestion-1] = e.target.value;
    localStorage.setItem('answers', JSON.stringify(a));
    setAnswers(a);
  
  }

  const onUserData = (e) => {
    e.preventDefault();
    //console.log(e.target.value);
  }

  const onStart = (e) => {
    e.preventDefault();
    setCurrentState(1)
  }

  /*

        {//<img src={toplogo} className="toplogo" /> }

        {//<img src={logo} className={currentState==0 ? "logobig" : "logosmall" } />}
          */

  return (
    <ThemeProvider theme={themeOptions}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box mt={4} mx={0}
          sx={{
            textAlign: "center" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          px={5}
        >


        <img src={currentState==0 ? logoBig : logoSmall } className={currentState==0 ? "logoBig" : "logoSmall" } />


        
        {currentState == 0 && <Welcome onUserData={onUserData} onStart={onStart} isValid={isValid} setIsValid={setIsValid} isValidEmail={isValidEmail} name={name} acceptGDPR={acceptGDPR} email={email} onAcceptGDPR={onAcceptGDPR} setUserName={setUserName} setUserEmail={setUserEmail} gameID={gameID}  /> }
        {currentState == 1 && <QuestionForm currentQuestion={currentQuestion} answers={answers} totalQuestions={totalQuestions} onPrevQuestion={onPrevQuestion} onNextQuestion={onNextQuestion} onChange={onChange} postAnswers={postAnswers}  /> }
        {currentState == 2 && <Submitted /> }
      </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
