import { Radio } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from "@mui/system";
import React from "react";

export default function NavIndicator({totalQuestions, currentQuestion}) {

    const checkIfAnswered = (id) => {
        if(localStorage.getItem("answers") != null) {
            const answers = JSON.parse(localStorage.getItem("answers"));
            return answers[id] != null;
        }
        else {
            return false;
        }
    }

    const getCircleColor = (id) => {
        if(currentQuestion == id) {
            return "#EF722F";
        }
        else {
            return "#fff";
        }
    }

    const generateIndicators = () => {
        //console.log("totalQuestions", totalQuestions);
        var indicators = []

        for(let i = 0; i < totalQuestions; i++) {
            indicators.push(<Radio
            sx={{ margin: "0", padding: "5px" }}
            key={i}
            checked={checkIfAnswered(i)}
            selected={true}
            disabled
            value="a"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
            icon={<RadioButtonUncheckedIcon sx={{ fontSize:"15px", color: getCircleColor(i+1) }} />}
            checkedIcon={
                <CircleIcon sx={{ fontSize:"15px", color: getCircleColor(i+1) }} />
            }
            />)
        }

        //console.log(indicators)

        return <>{indicators}</>;
    }

    return (
        <Box 
            display="flex" 
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            fullWidth

            sx={{marginBottom: "10px", marginTop: "0px"}}
        >
        {generateIndicators(totalQuestions)}
        </Box>
    )
}