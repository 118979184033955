import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, TextField, Box, AppBar, Input, Toolbar, makeStyles } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import NavIndicator from "./NavIndicator";
import { maxWidth } from "@mui/system";

export default function QuestionForm({currentQuestion, answers, totalQuestions, onPrevQuestion, onNextQuestion, onChange, postAnswers}) {
    //<TextField sx={{ mx: "20px", textAlign:"center", fontSize: "4em", maxWidth:"230px" }} id="standard-basic" label="DIN GISSNING" variant="standard" type='number' inputProps={{ inputMode: 'numeric' }} onChange={onChange} value={answers[currentQuestion - 1] || ''} />
    
    const [placeholder, setPlaceholder] = React.useState("DIN GISSNING");
    const [guess, setGuess] = React.useState("");
    const [fontSize, setFontSize] = React.useState("1em");

    useEffect(() => {
        setGuess(answers[9] || "");
    }, [answers]);

    
    const onChangeGuess = (e) => {
        setPlaceholder(e.target.value);
        if (isNaN(e.target.value)) return false;
        if ((e.target.value) > 9999) return false;
        setGuess(e.target.value);
        onChange(e) 
        //updateFontSize(e.target.value)      
    }

    const clearPlaceholder = (e) => {
        setPlaceholder("");
        setFontSize("2.5em")
    }

    const showPlaceholderText = (e) => {
        //console.log(e.target.value)
        setPlaceholder("DIN GISSNING");
        if(e.target.value == "") {
            setFontSize("1em")
        }
    }



    return (

            <FormControl >
            {currentQuestion < totalQuestions && <FormLabel  sx={{ color: '#FFFFFF', fontSize: "1.3em", textAlign: "center", marginTop: "40px", marginBottom: "30px" }} id="demo-controlled-radio-buttons-group">FRÅGA {currentQuestion}</FormLabel> }
            {currentQuestion == totalQuestions && <FormLabel  sx={{ color: '#FFFFFF', fontSize: "0.8em", textAlign: "center", marginTop: "40px", marginBottom: "30px" }} id="demo-controlled-radio-buttons-group">UTSLAGSFRÅGA</FormLabel> }

            {currentQuestion < totalQuestions &&
            <Box 
                display="flex" 
                alignItems="center"
                justifyContent="center"
                with="100%"
                
            >
            <RadioGroup 
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={answers[currentQuestion - 1] || ''}
                onChange={onChange}
                sx={{ display: "flex", color: '#FFFFFF', fontSize: "1.3em", textAlign: "center" }}
            >
                <FormControlLabel sx={{ padding: "0" }} value="1" control={<Radio />} label="1" />
                <FormControlLabel sx={{ padding: "0" }}value="X" control={<Radio />} label="X" />
                <FormControlLabel sx={{ padding: "0" }}value="2" control={<Radio />} label="2" />
            </RadioGroup>
            </Box>
            
            }            

            {currentQuestion === totalQuestions &&
            <Input placeholder={placeholder} sx={ {input: {textAlign: "center", fontSize: {fontSize}, caretColor:"#EF722F" } }} onFocus={() => clearPlaceholder()} onBlur={showPlaceholderText} type='text' inputProps={{ inputMode: 'numeric' }} onChange={onChangeGuess} value={guess || ''}  /> }
             
             <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, background: '#0F1A25' }}>
             <Box 
                display="flex" 
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                fullWidth

                sx={{margin: "12px 12px 0px 12px" }}
            >
            {currentQuestion > 0 && <Button sx={{ margin: "10px", color: '#FFFFFF', fontSize: "0.5em", width: "100%", maxWidth: "300px" }} startIcon={<ArrowBackIcon />} variant="contained" onClick={onPrevQuestion}>Bakåt</Button> }
            {currentQuestion < totalQuestions && <Button   sx={{ margin: "10px",color: '#FFFFFF', fontSize: "0.5em", width: "100%", maxWidth: "300px"}} endIcon={<ArrowForwardIcon />} variant="contained" disabled={answers[currentQuestion - 1] == null} onClick={onNextQuestion}>Nästa fråga</Button> }
            {currentQuestion === totalQuestions && <Button   sx={{ margin: "10px",color: '#FFFFFF', fontSize: "0.5em", width: "100%", maxWidth: "300px" }} endIcon={<CheckIcon />} variant="contained" disabled={answers[currentQuestion - 1] ==null || answers[currentQuestion - 1] ==""} onClick={postAnswers}>Skicka in</Button> }
            
            </Box>
            <NavIndicator totalQuestions={totalQuestions} currentQuestion={currentQuestion}></NavIndicator>
            </AppBar>
            <Toolbar sx={{margin: "50px" }} />
            </FormControl>
            
    )
}