import React, {useState, useEffect} from "react";
import { Button, Checkbox, FormControlLabel, Stack, TextField, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { bodyFont } from '../themeOptions';
import { fontSize } from "@mui/system";

export default function Welcome({ onUserData, onStart, isValid, setIsValid, isValidEmail,  name, acceptGDPR, email, onAcceptGDPR, setUserName, setUserEmail, gameID }) {
   
    return (

        <Stack spacing={0} sx={{marginLeft: "10px", marginRight: "10px"}}>
              {gameID == 1 && <Typography sx={{fontSize: "1.1em", textAlign: "center"}}>QUIZ</Typography> }
            {gameID == 2 && <Typography sx={{fontSize: "1em", textAlign: "center"}}>BARNQUIZ</Typography> }
           
            <Typography sx={{fontFamily: 'Montserrat', fontSize: "0.4em", textAlign:"left", marginTop: "0px", marginBottom: "20px"}}>
            Välkomna till Växjö Lakers VIP-kväll!
            Testa på att ha säsongskort, mingla med spelarna, ät korv och spana in omklädningsrummet. Tipspromenad med fina priser.
            <b className="orange"> Lycka till!</b>
            </Typography>

          
           
            <ThemeProvider theme={bodyFont}>
            <TextField sx={{fontFamily: 'Montserrat', fontSize: "0.7em"}} 
                            id="standard-basic" 
                            required label="Namn" 
                            variant="standard" 
                            value={name}  
                            onChange={setUserName}     />
            <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}  
                            onChange={setUserEmail}                      
                          />
            
               
            </ThemeProvider>
            <FormControlLabel
                    control={<Checkbox color="primary" checked={acceptGDPR}
                    onChange={onAcceptGDPR} />}
                    label={
                    <Typography sx={{fontFamily: 'Montserrat', fontSize: "0.25em", textAlign: "left"}}>Jag godkänner att mina personuppgifter lagras i enlighet med GDPR. Läs om hur vi lagrar dina personuppgifter här: <a href="https://www.vaxjolakers.se/gdpr" className="orange" target="_blank">www.vaxjolakers.se/gdpr</a></Typography>
                    }
                />
                 
            <Button sx={{ color: '#FFFFFF', marginBottom:"10px", marginTop:"10px" }} disabled={!isValid} variant="contained" onClick={onStart}>STARTA!</Button> 
            <Toolbar sx={{margin: "50px" }} />

        </Stack>
    )
}