import { Stack, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, textAlign } from "@mui/system";

export default function Submitted() {
    return (
        <Stack spacing={2} sx={{textAlign: "center"}}>
           

            <Typography sx={{ fontSize: "2em", textAlign:"center", marginTop: "30px"}}>
            TACK!
            </Typography>
            <Typography sx={{fontFamily: 'Montserrat', fontSize: "0.6em", textAlign:"left", marginTop: "10px", marginBottom: "20px"}}>
            Tack för att du har deltagit, vi meddelar tre vinnare via e-post under veckan.
            </Typography>
            <Box
                display="flex" 
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                fullWidth
                sx={{marginTop: "30px", padding: "30px" }}
            >
                  <CheckCircleIcon sx={{ textAlign: "center",fontSize:"2.5em", color: "primary.main" }}/>
            </Box>
          

        </Stack>
        
    )
}